<div *ngIf="!showEnrollmentsCard && !globalVariables.showCheckoutPaymentReponsePage" class="mb-5 quick-enr">
  <div class="d-flex justify-content-center">
    <div class="form">
      <hos-guest-form [config]="buttonDetails"></hos-guest-form>
    </div>
  </div>
</div>
<section *ngIf="showEnrollmentsCard && !globalVariables.showCheckoutPaymentReponsePage" class="enrolled-services-section page-wrap">
  <div class="row">
    <div class="col-12 col-md-12 quick-checkout-title">Renew / Re-Activate My Enrollments</div>
    <div class="col-12 col-md-12" style="margin-bottom: 30px">
      <div class="enrolled-services">
        <div class="col-12 col-md-12 pt-3 pr-0 pl-0 recommended-property-block">
          <hos-checkout-customer-accounts-activity
            *ngIf="showEnrollmentsCard"
            [services]="enrollmentCardsDetails"
            [user]="userDataToken"
            [locationCodeData]="showEnrollmentCardsSubData.locationCodeData"
            [userDetailsWithAddress]="serviceCustomerData"
            [renewedEnrollments]="renewedEnrollments"
          ></hos-checkout-customer-accounts-activity>
        </div>
      </div>
    </div>
  </div>
  <section *ngIf="enrollmentCardsDetails && enrollmentCardsDetails.length === 0">
    <section class="mb-5">
      <div class="container-fluid row p-0 m-0">
        <div class="col-12 col-md-12 p-0">
          <div class="recommended-services">
            <div class="col-12 col-md-12 title-search-block">SERVICES RECOMMENDATIONS</div>
            <div class="col-12 col-md-12 recommended-property-block">
              <article class="row paddB70 productCarousel" *ngIf="otherProducts && otherProducts.length > 0">
                <ngu-carousel [inputs]="carouselTile" [dataSource]="otherProducts" class="category-tile col-12">
                  <ngu-tile *nguCarouselDef="let product of otherProducts; let i = index" class="item">
                    <hos-product-card-qc-response-page
                      [index]="i"
                      [productcountid]="i + 1"
                      [locData]="locData"
                      [products]="otherProducts"
                      [product]="product"
                      [zipcode]="zipcode"
                      [locationId]="locationId"
                      [showOldPrice]="setOldPriceFlag"
                    ></hos-product-card-qc-response-page>
                  </ngu-tile>
                  <span
                    NguCarouselPrev
                    class="cat-slide-icon left-cat-icon fa fa-chevron-left"
                    [ngClass]="{ 'hide-slider': otherProducts?.length <= 4 }"
                  ></span>
                  <span
                    NguCarouselNext
                    class="cat-slide-icon right-cat-icon fa fa-chevron-right"
                    [ngClass]="{ 'hide-slider': otherProducts?.length <= 4 }"
                  ></span>
                </ngu-carousel>

                <div class="col-12 col-sm-12 col-md-12 mb-5 text-center" *ngIf="otherProducts?.length === 0">No other services available.</div>
              </article>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</section>
<hos-ets-payment-response
  *ngIf="globalVariables.showCheckoutPaymentReponsePage && !globalVariables.isSuccessCheckoutWebRegistration"
  [customerDetails]="customerDetails"
  [orderId]="globalVariables.customerAccountOrderCuid"
  [enrollmentGuid]="globalVariables.customerAccountEnrollmentGuid"
  [customerName]="userName"
  [existingCustomerData]="serviceCustomerData"
  [passNewRegisterCustomerResponse]="globalVariables.passNewRegisterCustomerAccount"
  [EmailExistedData]="EmailExistedData?.user"
  [addressPresentIn]="addressPresentIn"
></hos-ets-payment-response>
<div class="alert alert-success final-success-alert text-center" role="alert" *ngIf="globalVariables.isSuccessCheckoutWebRegistration">
  <div class="page-wrap row">
    <img src="/img/payment/Message-Ico-Success.svg" class="col-4 col-md-3" alt="Success Icon" />
    <span class="text-msg col-8 col-md-9 text-left">Your web account registration is complete!</span>
  </div>
</div>
<section class="content-section content-block register-section" *ngIf="globalVariables.isSuccessCheckoutWebRegistration">
  <div class="row page-wrap no-gutters">
    <div class="final-form-box step-3">
      <div class="row justify-content-center mt-2 mb-2">
        <div class="col-md-6 content-confirmation">
          <p>
            <strong>Hello {{ existingCustomerData.firstName || user.firstName }} {{ existingCustomerData.lastName || user.lastName }},</strong>
          </p>
          <div class="text-justify">
            <p>Thank for registering your online account. You are all set and can now login to your account!</p>
            <p>You will receive an email to verify your email address.</p>
            <p>If you do not receive the email to your inbox, please be sure to check your spam folder as well.</p>
          </div>
        </div>
        <div class="col-md-6">
          <hos-login-page [isRegisterSucess]="true"></hos-login-page>
        </div>
      </div>
    </div>
    <div class="things-to-do">
      <hos-things-you-can-do [page]="'register'"></hos-things-you-can-do>
    </div>
  </div>
</section>
<div [ngClass]="{ loading: showLoader }"></div>
