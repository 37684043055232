<div class="alert alert-success final-success-alert text-center" role="alert" *ngIf="step3Access">
  <div class="page-wrap row">
    <img src="/img/payment/Message-Ico-Success.svg" class="col-4 col-md-3" />
    <span class="text-msg col-8 col-md-9 text-left">Your web account registration is complete!</span>
  </div>
</div>
<div class="register-header" *ngIf="!step3Access">
  <section class="banner-box curve-mask">
    <div class="banner">
      <div class="content-box">
        <div class="page-wrap banner-content-box">
          <div class="col-12">
            <p class="form-header-text">Register</p>
            <div class="wrapper header-sub-text" [ngClass]="{ 'text-left': showNewForm !== undefined }">
              <p [ngClass]="showNewForm !== undefined ? 'ml-0 mt-3' : 'mx-auto'">
                Welcome! Thank you for choosing <span class="skiptranslate">American Water Resources</span> for home protection.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="row select-registration" *ngIf="showNewForm === undefined">
        <div class="col-12 col-md-6 new-customer">
          <div class="speech-bubble speech-bubble-left">
            <div class="bubble-content ml-sm-auto widthIe">
              <img class="d-none d-sm-block registerStart" src="img/register/Register-Ima-D-02.svg" />
              <img class="d-sm-none mobile-img pr-2" src="img/register/Register-Ima-M-02.svg" />
              <div class="to-form-select">
                <button
                  class="btn white-button"
                  (click)="showNewForm = true; showCCPAUpdates()"
                  angulartics2On="click"
                  angularticsLabel="start_here"
                  angularticsValue="0"
                  angularticsAction="click"
                  angularticsCategory="button"
                >
                  <span>Start Here ></span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-6 valued-customer">
          <div class="speech-bubble speech-bubble-right">
            <div class="bubble-content ml-auto ml-sm-0 widthIe">
              <img class="d-none d-sm-block registerStart" src="img/register/Register-Ima-D-01.svg" />
              <img class="d-sm-none mobile-img pl-2" src="img/register/Register-Ima-M-01.svg" />
              <div class="to-form-select form-select-right">
                <button
                  class="btn white-button"
                  (click)="showNewForm = false; step1Access = true; showCCPAUpdates()"
                  angulartics2On="click"
                  angularticsLabel="sign_up"
                  angularticsValue="0"
                  angularticsAction="click"
                  angularticsCategory="button"
                >
                  Signup For Online Access >
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
<hos-breadcrumb [config]="breadcrumsConfig" class="register-breadcrumb"></hos-breadcrumb>
<div class="things-to-do" [hidden]="showNewForm !== undefined">
  <hos-things-you-can-do [page]="'register'"></hos-things-you-can-do>
</div>
<section class="content-section content-block register-section" [hidden]="showNewForm === undefined">
  <div class="row page-wrap no-gutters">
    <div class="right-form-box row col-12 no-gutters">
      <div class="arrow-steps clearfix col-12 col-md-10 mx-auto pull-right"></div>
      <div class="row col-12 col-md-12 col-lg-9 no-gutters" *ngIf="step0Access && showNewForm === true">
        <div class="homeowner-information left-side step-1 col-12">
          <div class="form-header row m-0">
            <img src="/img/MyProfile/Profile-Ico-01.svg" class="mt-auto px-4" />
            <span class="my-auto">Homeowner Information</span>
          </div>
          <div class="form-border py-4 px-5">
            <form #ownerRegisterForm="ngForm" novalidate (keypress)="formKeyPressHandler($event)">
              <label class="proerty-type">In order to enroll in protective services, we will need to confirm the following information.</label>
              <div class="commercial-info row">
                <div class="form-group col-12 col-md-12">
                  <label>Are you the homeowner?</label>
                  <div class="pt-2 pb-3 row">
                    <div class="col-1 col-md-1 mr-4">
                      <div class="custom-radio">
                        <input
                          type="radio"
                          class="custom-control-input"
                          id="property-homeowner"
                          [value]="true"
                          name="homeowner"
                          (click)="allowRegistration('owner', 1)"
                          [(ngModel)]="homeOwner.isHomeOwner"
                          #homeowner="ngModel"
                        />
                        <label class="custom-control-label property-value" for="property-homeowner">Yes</label>
                      </div>
                    </div>
                    <div class="col-1 col-md-1">
                      <div class="custom-radio">
                        <input
                          type="radio"
                          class="custom-control-input"
                          id="property-homeowner-no"
                          [value]="false"
                          name="homeowner"
                          (click)="allowRegistration('owner', 0)"
                          [(ngModel)]="homeOwner.isHomeOwner"
                          #homeowner="ngModel"
                        />
                        <label class="custom-control-label property-value" for="property-homeowner-no">No</label>
                      </div>
                    </div>
                    <div class="alert alert-danger" *ngIf="homeOwner.isHomeOwner !== null && !homeOwner.isHomeOwner">
                      Only homeowners can enroll in services.
                    </div>
                  </div>
                </div>
                <div class="form-group col-12 col-md-12" [ngClass]="{ disabled: !homeOwner.isHomeOwner }">
                  <label>Is this a rental property?</label>
                  <div class="pt-2 pb-3 row">
                    <div class="col-1 col-md-1 mr-4">
                      <div class="custom-radio">
                        <input
                          type="radio"
                          class="custom-control-input"
                          id="property-rental"
                          [value]="true"
                          name="rental"
                          (click)="allowRegistration('rental', 1)"
                          [(ngModel)]="homeOwner.allowRental"
                          #rental="ngModel"
                          [disabled]="!homeOwner.isHomeOwner"
                        />
                        <label class="custom-control-label property-value" for="property-rental">Yes</label>
                      </div>
                    </div>
                    <div class="col-1 col-md-1">
                      <div class="custom-radio">
                        <input
                          type="radio"
                          class="custom-control-input"
                          id="property-rental-no"
                          [value]="false"
                          name="rental"
                          (click)="allowRegistration('rental', 0)"
                          [(ngModel)]="homeOwner.allowRental"
                          #rental="ngModel"
                          [disabled]="!homeOwner.isHomeOwner"
                        />
                        <label class="custom-control-label property-value" for="property-rental-no">No</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="form-group col-12 col-md-12" [ngClass]="{ disabled: !homeOwner.allowRental }">
                  <label>Will your tenant's name at any time be on the water bill?</label>
                  <div class="pt-2 pb-3 row">
                    <div class="col-1 col-md-1 mr-4">
                      <div class="custom-radio">
                        <input
                          type="radio"
                          class="custom-control-input"
                          id="property-tenant-bill"
                          [value]="true"
                          name="tenantBill"
                          (click)="allowRegistration('tenantBill', 1)"
                          [(ngModel)]="homeOwner.offbillOnly"
                          #tenantBill="ngModel"
                          [disabled]="!homeOwner.allowRental"
                        />
                        <label class="custom-control-label property-value" for="property-tenant-bill">Yes</label>
                      </div>
                    </div>
                    <div class="col-1 col-md-1">
                      <div class="custom-radio">
                        <input
                          type="radio"
                          class="custom-control-input"
                          id="property-tenant-bill-no"
                          [value]="false"
                          name="tenantBill"
                          (click)="allowRegistration('tenantBill', 0)"
                          [(ngModel)]="homeOwner.offbillOnly"
                          #tenantBill="ngModel"
                          [disabled]="!homeOwner.allowRental"
                        />
                        <label class="custom-control-label property-value" for="property-tenant-bill-no">No</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <label class="form-message">All fields are required</label> -->
              <!-- <hr> -->
              <div class="form-group col-12 col-md-12 text-right p-0">
                <button
                  type="submit"
                  class="btn primary-btn block full-width m-b"
                  [ngClass]="{
                    disabled: !homeOwner.isHomeOwner || homeOwner.allowRental === null || (homeOwner.allowRental && homeOwner.offbillOnly === null)
                  }"
                  (click)="isClicked = true; goToStep('next', ownerRegisterForm, 0, $event, false)"
                  [disabled]="!homeOwner.isHomeOwner || homeOwner.allowRental === null || (homeOwner.allowRental && homeOwner.offbillOnly === null)"
                >
                  Next >
                </button>
                <!-- toDO-->
              </div>
            </form>
          </div>
        </div>
      </div>
      <div class="row col-12 col-md-12 col-lg-9 no-gutters" *ngIf="step1Access">
        <!-- General information left side area -->
        <div class="general-information left-side step-1 col-12" *ngIf="step1Access && showNewForm === true">
          <div class="form-border">
            <div class="form-header row m-0">
              <img src="/img/MyProfile/Profile-Ico-01.svg" class="mt-auto px-4" alt="Profile Icon" />
              <span class="my-auto">General Information</span>
            </div>
            <div class="px-4 hr-content mt-4">
              <span class="heading-text">If you do not have a web account, please enter your information in the fields below.</span>
            </div>

            <div class="py-4 px-4">
              <form #registerForm="ngForm" novalidate (keyup)="formKeyPressHandler($event, true)">
                <div class="row" id="name">
                  <div class="form-group col-12 col-md-6">
                    <label for="selectType">First Name<sup class="text-danger">*</sup></label>
                    <input
                      type="text"
                      class="form-control round-form"
                      [ngClass]="{ 'is-invalid': isClicked && !firstName.valid }"
                      name="firstName"
                      required
                      [(ngModel)]="user.firstName"
                      #firstName="ngModel"
                      maxlength="80"
                      [pattern]="blockUnicodeRegex"
                      (change)="user.firstName = user.firstName.trim()"
                      (blur)="saveToLocalStorage(registerForm)"
                    />
                    <small id="emailHelp" class="form-text text-danger" *ngIf="firstName.touched && firstName.errors?.required"
                      >First name is required.</small
                    >
                    <small id="emailHelp" class="form-text text-danger" *ngIf="firstName.errors && firstName.errors.pattern"
                      >Please remove special characters.</small
                    >
                  </div>
                  <div class="form-group col-12 col-md-6">
                    <label for="selectType">Last Name<sup class="text-danger">*</sup></label>
                    <input
                      type="text"
                      class="form-control round-form"
                      [ngClass]="{ 'is-invalid': isClicked && !lastName.valid }"
                      name="lastName"
                      required
                      [(ngModel)]="user.lastName"
                      #lastName="ngModel"
                      maxlength="80"
                      [pattern]="blockUnicodeRegex"
                      (change)="user.lastName = user.lastName.trim()"
                      (blur)="saveToLocalStorage(registerForm)"
                    />
                    <small id="emailHelp" class="form-text text-danger" *ngIf="lastName.touched && lastName.errors?.required"
                      >Last name is required.</small
                    >
                    <small id="emailHelp" class="form-text text-danger" *ngIf="lastName.errors && lastName.errors.pattern"
                      >Please remove special characters.</small
                    >
                  </div>

                  <div class="form-group col-12 col-md-6">
                    <label for="selectType">Middle Name (Optional)</label>
                    <input
                      type="text"
                      class="form-control round-form"
                      name="middleName"
                      [(ngModel)]="user.middleName"
                      #middleName="ngModel"
                      (blur)="saveToLocalStorage()"
                    />
                  </div>
                </div>
                <hr />

                <div class="row" id="email">
                  <div class="form-group col-12 col-md-6">
                    <label for="selectType">Email<sup class="text-danger">*</sup> (will be used as your Account ID)</label>
                    <input
                      type="email"
                      class="form-control round-form"
                      (keypress)="emailKeyPressHandler(email)"
                      (blur)="emailBlurHandler(email)"
                      (focusout)="abandonedRegistrationInsert($event)"
                      [ngClass]="{ 'is-invalid': (email.isBlur || isClicked) && !email.valid }"
                      [pattern]="email_regex"
                      name="email"
                      required
                      [(ngModel)]="user.email"
                      #email="ngModel"
                      maxlength="100"
                    />

                    <small id="emailHelp" class="form-text text-danger" *ngIf="isExistingUser && email.valid"
                      >The email address you have entered is already registered</small
                    >
                    <small id="emailHelp" class="form-text text-danger" *ngIf="email.isBlur && !email.valid">Please enter valid email address.</small>
                    <small id="emailHelp" class="form-text text-danger" *ngIf="(isClicked && !email.valid && !email.touched) || isDisposableEmail"
                      >Please enter valid email address.</small
                    >
                  </div>
                  <div class="form-group col-12 col-md-6"></div>
                  <div class="form-group col-12 col-md-6">
                    <label for="selectType">Password<sup class="text-danger">*</sup></label>
                    <input
                      [type]="isView ? 'text' : 'password'"
                      class="form-control round-form"
                      (blur)="blurHandler(password)"
                      [ngClass]="{ 'is-invalid': (isClicked || password.isBlur) && !password.valid }"
                      [pattern]="password_regex"
                      name="password"
                      required
                      ngModel
                      #password="ngModel"
                    />
                    <span (click)="isView = !isView" class="fa {{ isView ? 'fa-eye' : 'fa-eye-slash' }} pwd-toggle"></span>
                    <br />
                    <label for="selectType">Confirm Password<sup class="text-danger">*</sup></label>
                    <input
                      [type]="isConfirmView ? 'text' : 'password'"
                      class="form-control round-form"
                      (blur)="blurHandler(confirmPassword)"
                      [ngClass]="{ 'is-invalid': (isClicked || confirmPassword.isBlur) && !confirmPassword.valid }"
                      [pattern]="password_regex"
                      name="confirmPassword"
                      required
                      ngModel
                      #confirmPassword="ngModel"
                    />
                    <span (click)="isConfirmView = !isConfirmView" class="fa {{ isConfirmView ? 'fa-eye' : 'fa-eye-slash' }} pwd-toggle"></span>
                  </div>
                  <div class="form-group col-12 col-md-6">
                    <hos-password-strength-meter [password]="password.value" [confirmPassword]="confirmPassword.value"></hos-password-strength-meter>
                    <br />
                  </div>
                  <div class="form-group col-12 col-md-6">
                    <label for="selectType">Home Phone Number<sup class="text-danger">*</sup></label>
                    <input
                      type="text"
                      class="form-control round-form"
                      (blur)="phoneBlurHandler(phoneNumber)"
                      (keypress)="keyPressHandler(phoneNumber); numberOnly($event)"
                      maxlength="10"
                      minlength="10"
                      [ngClass]="{ 'is-invalid': isClicked && !phoneNumber.valid }"
                      [pattern]="phone_number_regex"
                      name="phoneNumber"
                      required
                      [(ngModel)]="user.phoneNumber"
                      #phoneNumber="ngModel"
                    />
                    <small id="emailHelp" class="form-text text-danger" *ngIf="isClicked && !homePhoneErrorMsg && !phoneNumber.valid"
                      >Please enter valid phone number.</small
                    >
                    <small id="emailHelp" class="form-text text-danger" *ngIf="phoneNumber.isBlur || isClicked">{{ homePhoneErrorMsg }}</small>
                    <!-- <small id="emailHelp" *ngIf="showPhoneError" class="form-text text-danger">This phone number already exists.</small> -->
                    <!-- HWR-2485 -->
                  </div>
                  <!-- [VJ HWR-2661-2654 MOBILE FIELD RELATED CODE] -->
                  <div class="form-group col-12 col-md-6">
                    <label for="selectType">Mobile Phone Number (Optional)</label>
                    <input
                      type="text"
                      class="form-control round-form"
                      (blur)="phoneBlurHandler(mobilePhoneNumber)"
                      (keypress)="keyPressHandler(mobilePhoneNumber); numberOnly($event)"
                      maxlength="10"
                      minlength="10"
                      [ngClass]="{ 'is-invalid': isClicked && !mobilePhoneNumber.valid }"
                      [pattern]="phone_number_regex"
                      name="mobilePhoneNumber"
                      [(ngModel)]="user.mobilePhoneNumber"
                      #mobilePhoneNumber="ngModel"
                    />
                    <small id="mobileNoHelp" class="form-text text-danger" *ngIf="isClicked && !mobilePhoneErrorMsg && !mobilePhoneNumber.valid"
                      >Please enter valid mobile phone number.</small
                    >
                    <small id="mobileNoHelp" class="form-text text-danger" *ngIf="mobilePhoneNumber.isBlur || isClicked">{{
                      mobilePhoneErrorMsg
                    }}</small>
                    <!-- <small id="emailHelp" *ngIf="showPhoneError" class="form-text text-danger">This phone number already exists.</small> -->
                    <!-- HWR-2485 -->
                  </div>

                  <div id="service-address" class="form-group col-12 col-md-12">
                    <div class="custom-checkbox">
                      <input
                        type="checkbox"
                        id="authorize-check"
                        name="authorizedTosenDMessage"
                        [(ngModel)]="user.isAuthorizedToSendMessage"
                        #authorizedTosenDMessage="ngModel"
                      />
                      <label for="authorize-check"
                        >Yes, I authorize <span class="skiptranslate">American Water Resources</span> to send me text messages. Standard
                        text-messaging rates may apply.</label
                      >
                    </div>
                  </div>
                </div>
                <hr />
                <div class="row">
                  <div class="form-group col-12 col-md-6">
                    <label for="selectType">Address<sup class="text-danger">*</sup></label>
                    <input
                      type="text"
                      autocomplete="off"
                      class="form-control round-form"
                      [ngClass]="{ 'is-invalid': (streetNumber.dirty || isClicked) && !streetNumber.valid }"
                      pattern="{{ streetNumberValidation }}"
                      name="streetNumber"
                      required
                      [(ngModel)]="user.address[0].serviceAddress.streetNumber"
                      (keyup)="getSuggestion($event)"
                      #streetNumber="ngModel"
                      maxlength="100"
                    />
                    <small id="emailHelp" class="form-text text-danger" *ngIf="(streetNumber.dirty || isClicked) && !streetNumber.valid"
                      ><span *ngIf="streetNumber.errors?.required">Address is required.</span
                      ><span *ngIf="streetNumber.errors?.pattern">Address is invalid.</span></small
                    >
                    <div class="showDropDownWrapper">
                      <div *ngIf="showDropDown" class="search-drop-down">
                        <div
                          (click)="selectValue(addr.street_line, addr.city, 1)"
                          class="search-results"
                          *ngFor="let addr of suggestedAddress.suggestions"
                        >
                          <a [innerHTML]="addr.street_line + ', ' + addr.city"> {{ addr.street_line }}, {{ addr.city }} </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-12 col-md-6">
                    <label for="selectType">Apt., Unit, PO Box, etc.</label>
                    <input
                      type="text"
                      autocomplete="off"
                      class="form-control round-form"
                      [ngClass]="{ 'is-invalid': isClicked && !streetName.valid }"
                      name="streetName"
                      [(ngModel)]="user.address[0].serviceAddress.streetName"
                      #streetName="ngModel"
                      maxlength="100"
                      (blur)="saveToLocalStorage()"
                    />
                    <small id="emailHelp" class="form-text text-danger" *ngIf="isClicked && !streetName.valid"
                      >Apt., Unit, PO Box, etc. is required.</small
                    >
                  </div>
                  <div class="form-group col-12 col-md-6">
                    <label for="selectType">City<sup class="text-danger">*</sup></label>
                    <input
                      type="text"
                      class="form-control round-form"
                      [ngClass]="{ 'is-invalid': isClicked && !city.valid }"
                      name="city"
                      required
                      [(ngModel)]="user.address[0].serviceAddress.city"
                      #city="ngModel"
                      maxlength="80"
                      (blur)="saveToLocalStorage()"
                    />
                    <small id="emailHelp" class="form-text text-danger" *ngIf="isClicked && !city.valid">City address is required.</small>
                  </div>
                  <div class="form-group col-12 col-md-6">
                    <label for="selectType">ZIP Code<sup class="text-danger">*</sup></label>
                    <hos-zipcode
                      [disabled]="isZipcodeReadOnly"
                      [isClicked]="isClicked"
                      [address]="user.address[0].serviceAddress"
                      (focusout)="abandonedRegistrationInsert($event)"
                      (notify)="onZipcodeUpdated($event)"
                      [serviceUnavailable]="productsUnavailable"
                    ></hos-zipcode>
                  </div>
                  <div class="form-group col-12 col-md-6">
                    <label for="selectType">State</label>
                    <ng-select
                      [disabled]="true"
                      [items]="states"
                      bindLabel="value"
                      bindValue="value"
                      (change)="stateChangeHandler($event)"
                      [(ngModel)]="user.address[0].serviceAddress.state"
                      name="state"
                      #state="ngModel"
                      class="skiptranslate state-arrow"
                      required
                    >
                    </ng-select>
                  </div>
                </div>
                <hr />
                <!-- HWR-5025 Removal of Checkbox from Web Account Registration -->
                <!-- <div class="col-12 col-md-12 home-owner" id="service-address">
                                    <div class="custom-checkbox highlight-regitration-checkbox">
                                        <input autocomplete="off" id="authorizeinfocheck" [(ngModel)]="isConfirmation" name="authorizeinfo-check" type="checkbox" [disabled]="productsUnavailable">
                                        <label class="authorizeinfo-check" for="authorizeinfocheck">By clicking the checkbox you are confirming
                                             that you are the homeowner, and your name is on the water bill.</label>
                                    </div>
                                </div> -->
                <section class="content-section bottom-action-section col-12 to-right">
                  <div class="row">
                    <a class="primary-link my-auto" (click)="cancelRegister()" *ngIf="step1Access">Cancel</a>
                    <div class="row to-right">
                      <button
                        type="submit"
                        class="btn primary-btn block full-width pull-right mt-0 mr-2"
                        (click)="isClicked = true; goToStep('next', registerForm, 1, $event, false)"
                      >
                        Register
                      </button>
                    </div>
                  </div>
                </section>
              </form>
            </div>
          </div>

          <!-- General information right side area -->
          <div class="general-information left-side step-1 col-12" *ngIf="step1Access && showNewForm === false">
            <div class="form-header row m-0">
              <img src="/img/MyProfile/Profile-Ico-01.svg" class="mt-auto profile-icon" alt="Profile Icon" />
              <span class="my-auto">General Information</span>
            </div>
            <div class="form-border py-4 align-form">
              <form #customerForm="ngForm" novalidate (keypress)="formKeyPressHandler($event)">
                <div class="row">
                  <!-- <div class="form-group col-12 col-md-6">
                                        <label for="selectType">First Name</label>
                                        <input type="text" class="form-control round-form" name="firstNameLookUp" ngModel #firstNameLookUp="ngModel" maxlength="80"
                                            required [ngClass]="{'is-invalid': (firstNameLookUp.dirty || invalidForm) && !firstNameLookUp.valid}">
                                        <small class="form-text text-danger" *ngIf="(firstNameLookUp.dirty || invalidForm) && !firstNameLookUp.valid">First name is required.</small>
                                    </div>

                                    <div class="form-group col-12 col-md-6">
                                        <label for="selectType">Last Name</label>
                                        <input type="text" class="form-control round-form" name="lastNameLookUp" ngModel #lastNameLookUp="ngModel" maxlength="80"
                                            required [ngClass]="{'is-invalid': (lastNameLookUp.dirty || invalidForm) && !lastNameLookUp.valid}">
                                        <small class="form-text text-danger" *ngIf="(lastNameLookUp.dirty || invalidForm) && !lastNameLookUp.valid">Last name is required.</small>
                                    </div> -->

                  <div class="form-group col-12 col-md-6">
                    <label for="selectType" class="hr-line"
                      >Customer Number<sup class="text-danger">*</sup>
                      <span class="pull-right tooltip-container">
                        <i
                          class="fa fa-question-circle"
                          aria-hidden="true"
                          placement="top"
                          tooltip="Don’t know your account number, please call customer service {{ customer_service_number }}"
                        ></i>
                      </span>
                    </label>
                    <input
                      type="text"
                      class="form-control round-form"
                      name="accountNumber"
                      [pattern]="customer_number_regex"
                      ngModel
                      #accountNumber="ngModel"
                      maxlength="100"
                      [ngClass]="{ 'is-invalid': (accountNumber.dirty || invalidForm) && !accountNumber.valid }"
                      (keyup)="onCustomerNumberChange()"
                      required
                    />
                    <small class="form-text text-danger" *ngIf="(accountNumber.dirty || invalidForm) && !accountNumber.valid"
                      >Please enter a properly formatted Customer Number - CUS-XXXXXXXXXXXXX</small
                    >
                    <small class="form-text text-danger" *ngIf="accountNumber.dirty && customerExistInHOS"
                      >This customer number you have entered is already registered.</small
                    >
                  </div>
                  <div class="form-group col-12 col-md-6 text-right p-0 mt-auto">
                    <button
                      type="submit"
                      class="btn primary-btn block full-width m-b mr-3"
                      (click)="invalidForm = true; accountNumberKeyPressHandler(customerForm)"
                    >
                      Next >
                    </button>
                  </div>
                </div>
              </form>
            </div>
            <form #customerAddressForm="ngForm" novalidate (keypress)="formKeyPressHandler($event)">
              <div class="customer-section mb-2 py-4 align-form">
                <h5 class="mb-5">
                  <span class="pr-0 head-text-existing">Forgot your customer number? Complete the information below to continue registration</span>
                </h5>
                <div class="row">
                  <!-- <div class="form-group col-12 col-md-6">
                                        <label for="selectType">First Name</label>
                                        <input type="text" class="form-control round-form" ngModel name="firstNameExisting" #firstNameExisting="ngModel" maxlength="80"
                                            required [ngClass]="{'is-invalid': (firstNameExisting.dirty || isClickedForm) && !firstNameExisting.valid}"
                                            name="firstNameExisting">
                                        <small id="emailHelp" class="form-text text-danger" *ngIf="(firstNameExisting.dirty || isClickedForm) && !firstNameExisting.valid">First name is required.</small>
                                    </div>

                                    <div class="form-group col-12 col-md-6">
                                        <label for="selectType">Last Name</label>
                                        <input type="text" class="form-control round-form" ngModel name="lastNameExisting" #lastNameExisting="ngModel" maxlength="80"
                                            required [ngClass]="{'is-invalid': (lastNameExisting.dirty || isClickedForm) && !lastNameExisting.valid}"
                                            name="lastNameExisting">
                                        <small id="emailHelp" class="form-text text-danger" *ngIf="(lastNameExisting.dirty || isClickedForm) && !lastNameExisting.valid">Last name is required.</small>
                                    </div>
                                    <hr> -->
                  <div class="form-group col-12 col-md-6">
                    <label for="selectType">Address</label>
                    <input
                      type="text"
                      autocomplete="off"
                      class="form-control round-form"
                      [ngClass]="{ 'is-invalid': (streetNumberExisting.dirty || isClickedForm) && !streetNumberExisting.valid }"
                      pattern="{{ streetNumberValidation }}"
                      (keyup)="getSuggestion($event)"
                      name="streetNumberExisting"
                      required
                      [(ngModel)]="addressLookup.streetNumber"
                      #streetNumberExisting="ngModel"
                      maxlength="100"
                    />
                    <small
                      id="emailHelp"
                      class="form-text text-danger"
                      *ngIf="(streetNumberExisting.dirty || isClickedForm) && !streetNumberExisting.valid"
                      ><span *ngIf="streetNumberExisting.errors?.required">Address is required.</span
                      ><span *ngIf="streetNumberExisting.errors?.pattern">Address is invalid.</span></small
                    >
                    <div class="showDropDownWrapper">
                      <div *ngIf="showDropDown" class="search-drop-down">
                        <div
                          (click)="selectValue(addr.street_line, addr.city, 2)"
                          class="search-results"
                          *ngFor="let addr of suggestedAddress.suggestions"
                        >
                          <a [innerHTML]="addr.street_line + ', ' + addr.city"> {{ addr.street_line }}, {{ addr.city }} </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group col-12 col-md-6">
                    <label for="selectType">Apt., Unit, PO Box, etc.</label>
                    <input
                      type="text"
                      autocomplete="off"
                      class="form-control round-form"
                      [ngClass]="{ 'is-invalid': (streetNameExisting.dirty || isClickedForm) && !streetNameExisting.valid }"
                      name="streetNameExisting"
                      [(ngModel)]="addressLookup.streetName"
                      #streetNameExisting="ngModel"
                      maxlength="100"
                    />
                    <small
                      id="emailHelp"
                      class="form-text text-danger"
                      *ngIf="(streetNameExisting.dirty || isClickedForm) && !streetNameExisting.valid"
                      >Apt., Unit, PO Box, etc. is required.</small
                    >
                  </div>
                  <div class="form-group col-12 col-md-6">
                    <label for="selectType">City</label>
                    <input
                      type="text"
                      class="form-control round-form"
                      [ngClass]="{ 'is-invalid': (cityExisting.dirty || isClickedForm) && !cityExisting.valid }"
                      name="cityExisting"
                      required
                      [(ngModel)]="addressLookup.city"
                      #cityExisting="ngModel"
                      maxlength="80"
                    />
                    <small id="emailHelp" class="form-text text-danger" *ngIf="(cityExisting.dirty || isClickedForm) && !cityExisting.valid"
                      >City address is required.</small
                    >
                  </div>
                  <div class="form-group col-12 col-md-6">
                    <label for="selectType">ZIP Code</label>
                    <hos-zipcode
                      [disabled]="isZipcodeReadOnly"
                      (focusout)="abandonedRegistrationInsert($event)"
                      [isClicked]="isClickedForm"
                      [address]="addressLookup"
                      (notify)="onZipcodeUpdatedLookup($event)"
                      [serviceUnavailable]="productsUnavailable"
                    ></hos-zipcode>
                  </div>
                  <hr />
                  <div class="form-group col-12 col-md-6">
                    <label for="selectType">Phone Number</label>
                    <input
                      type="text"
                      class="form-control round-form"
                      (blur)="blurHandler(phoneNumberExisting)"
                      (keypress)="keyPressHandler(phoneNumberExisting); numberOnly($event)"
                      maxlength="10"
                      minlength="10"
                      [ngClass]="{ 'is-invalid': (phoneNumberExisting.dirty || isClickedForm) && !phoneNumberExisting.valid }"
                      [pattern]="phone_number_regex"
                      name="phoneNumberExisting"
                      required
                      [(ngModel)]="addressLookup.phoneNumber"
                      #phoneNumberExisting="ngModel"
                    />
                    <small
                      id="emailHelp"
                      class="form-text text-danger"
                      *ngIf="isClickedForm && !phoneNumberExistingErrorMsg && !phoneNumberExisting.valid"
                      >Please enter valid phone number.</small
                    >
                    <small id="emailHelp" class="form-text text-danger" *ngIf="phoneNumberExisting.isBlur || isClickedForm">{{
                      phoneNumberExistingErrorMsg
                    }}</small>
                  </div>
                  <div id="service-address" class="form-group col-12 col-md-12">
                    <div class="custom-checkbox">
                      <input
                        type="checkbox"
                        id="authorize-check-exist"
                        name="authorizedTosenDMessages"
                        [(ngModel)]="addressLookup.isAuthorizedToSendMessage"
                        #authorizedTosenDMessages="ngModel"
                      />
                      <label for="authorize-check-exist"
                        >Yes, I authorize <span class="skiptranslate">American Water Resources</span> to send me text messages. Standard
                        text-messaging rates may apply.</label
                      >
                    </div>
                  </div>
                </div>
                <label class="form-message set-align">All fields are required</label>
                <hr class="my-1" />
                <div class="form-group col-12 col-md-12 text-right p-0">
                  <button
                    type="submit"
                    [disabled]="!zipcodeSet"
                    class="btn primary-btn block full-width m-b"
                    (click)="isClickedForm = true; accountNumberKeyPressHandler(customerAddressForm)"
                  >
                    Next >
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <!-- Credentials information area -->
      <div class="credential-information step-2 col-12 col-md-12 col-lg-9 row no-gutters" *ngIf="step2Access && isExistingCustReg">
        <form #registerForm="ngForm" class="mb-4 col-12 left-side" novalidate (keypress)="formKeyPressHandler($event)">
          <div class="form-header row m-0 col-12">
            <img src="/img/MyProfile/Profile-Ico-02.svg" class="mt-auto px-4" alt="Profile Icon" />
            <span class="my-auto">Credentials</span>
          </div>
          <div class="form-border py-4 px-5">
            <div class="row" id="credential-section">
              <div class="form-group col-12 col-md-6">
                <label for="selectType">Primary Email (will be used as your account id)</label>
                <input
                  type="email"
                  class="form-control round-form"
                  name="loginId"
                  (keypress)="emailKeyPressHandler(loginId)"
                  (blur)="emailBlurHandler(loginId)"
                  (focusout)="abandonedRegistrationInsert($event)"
                  [pattern]="email_regex"
                  required
                  [(ngModel)]="user.email"
                  #loginId="ngModel"
                  [ngClass]="{ 'is-invalid': (loginId.isBlur || isClicked) && !loginId.valid && existingUser?.accountnumber }"
                />
                <small id="emailHelp" class="form-text text-danger" *ngIf="isExistingUser && loginId.valid && existingUser?.accountnumber"
                  >The email address you have entered is already registered</small
                >
                <small id="emailHelp" class="form-text text-danger" *ngIf="loginId.isBlur && !loginId.valid && existingUser?.accountnumber"
                  >Please enter valid email address.</small
                >
                <small
                  id="emailHelp"
                  class="form-text text-danger"
                  *ngIf="(isClicked && !loginId.valid && !loginId.touched && existingUser?.accountnumber) || isDisposableEmail"
                  >Please enter valid email address.</small
                >
              </div>
              <div class="form-group col-12 col-md-6"></div>
              <div class="form-group col-12 col-md-6">
                <label for="selectType">Password</label>
                <input
                  [type]="isView ? 'text' : 'password'"
                  class="form-control round-form"
                  (blur)="blurHandler(password)"
                  [ngClass]="{ 'is-invalid': (isClicked || password.isBlur) && !password.valid }"
                  [pattern]="password_regex"
                  name="password"
                  required
                  ngModel
                  #password="ngModel"
                />
                <span (click)="isView = !isView" class="fa {{ isView ? 'fa-eye' : 'fa-eye-slash' }} pwd-toggle"></span>
                <br />
                <label for="selectType">Confirm Password</label>
                <input
                  [type]="isConfirmView ? 'text' : 'password'"
                  class="form-control round-form"
                  (blur)="blurHandler(confirmPassword)"
                  [ngClass]="{ 'is-invalid': (isClicked || confirmPassword.isBlur) && !confirmPassword.valid }"
                  [pattern]="password_regex"
                  name="confirmPassword"
                  required
                  ngModel
                  #confirmPassword="ngModel"
                />
                <span (click)="isConfirmView = !isConfirmView" class="fa {{ isConfirmView ? 'fa-eye' : 'fa-eye-slash' }} pwd-toggle"></span>
              </div>
              <div class="form-group col-12 col-md-6">
                <hos-password-strength-meter [password]="password.value" [confirmPassword]="confirmPassword.value"></hos-password-strength-meter>
                <br />
              </div>
            </div>
            <label class="form-message">All fields are required</label>
            <hr />
            <section class="content-section bottom-action-section col-12 to-right">
              <div class="row">
                <a class="primary-link my-auto" (click)="cancelRegister()" *ngIf="step2Access">Cancel</a>
                <div class="row to-right">
                  <button
                    class="btn primary-btn block full-width pull-right mt-0 previous-button"
                    (click)="goToStep('previous', registerForm, 2, $event, true); isClicked = false"
                    *ngIf="step2Access"
                    (focusout)="abandonedRegistrationInsert()"
                  >
                    < Previous
                  </button>
                  <button
                    type="submit"
                    class="btn primary-btn block full-width pull-right mt-0 mr-2"
                    (click)="goToStep('next', registerForm, 2, $event, true); isClicked = true"
                    [disabled]="productsUnavailable"
                    *ngIf="step1Access || step2Access"
                  >
                    Next >
                  </button>
                </div>
              </div>
            </section>
          </div>
        </form>
      </div>

      <div class="col-12 col-md-6 col-lg-3 mt-4" *ngIf="!step3Access">
        <div class="right-panel row about-registration no-gutters">
          <div class="pt-1 col-2">
            <img class="widthIe" src="/img/various/Need-Help-Ico.svg" alt="Need Help Icon" />
          </div>
          <div class="text-left col-10 pr-0 pl-2">
            <h3 class="about-head">Questions about Registration?</h3>
            <p class="about-body">
              <span>Call customer service </span>
              <strong
                ><a href="tel:{{ customer_service_number }}">{{ customer_service_number }}</a></strong
              >
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="final-form-box step-3" *ngIf="step3Access">
      <div class="row justify-content-center mt-2 mb-2">
        <div class="col-md-6 content-confirmation">
          <p>
            <strong>Hello {{ existingUser.firstName || user.firstName }} {{ existingUser.lastName || user.lastName }},</strong>
          </p>
          <p>Thank for registering your online account. You are all set and can now login to your account!</p>
          <p>You will receive an email to verify your email address.</p>
          <p>If you do not receive the email to your inbox, please be sure to check your spam folder as well.</p>
        </div>
        <div class="col-md-6">
          <hos-login-page [isRegisterSucess]="step3Access"></hos-login-page>
        </div>
      </div>
    </div>

    <div class="things-to-do" *ngIf="step3Access">
      <hos-things-you-can-do [page]="'register'"></hos-things-you-can-do>
    </div>
    <div class="success-register" [class.showDiv]="step3Access && setSuccessfulRegister">
      <button id="successful_register" class="btn"></button>
    </div>
  </div>
</section>

<div [ngClass]="{ loading: showLoader }"></div>
