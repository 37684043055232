import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { GuestFormService } from '../../services/guest-form.service';
import { GlobalVariables } from '../../../core/services/global-variables.service';

@Component({
  selector: 'hos-guest-form',
  templateUrl: './guest-form.component.html',
  styleUrls: ['./guest-form.component.scss'],
})
export class GuestFormComponent implements OnInit {
  @Input()
  config = {
    hasHomeOwnerCheckbox: false,
    hasTerms: false,
    submitButtonText: 'Continue to Payment Method',
    forUpdateRenewal: true,
    formHeading: 'If you do not know your customer number, please enter your information in the fields below.'
  };

  @ViewChild('registerForm')
  registerForm: any;

  constructor(public guestFormService: GuestFormService, private globalVariables: GlobalVariables) { }

  ngOnInit() {
    this.guestFormService.initilize();
  }

  reEnableCartIcon() {
    this.globalVariables.isEnrollmentUpdatePage.emit(true);
  }
}
